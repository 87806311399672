import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MultiselectDropdown = ({
  size = 'small',
  options,
  grouped,
  selected,
  label,
  field: { onChange, ...fieldProps },
  form: { setFieldValue },
  ...props
}) => {
  const { name, value } = fieldProps;

  const defaultValues = options.filter(item => value.includes(item.id));

  return (
    <Autocomplete
      {...props}
      multiple
      options={options}
      disableCloseOnSelect
      groupBy={option => (grouped ? option.groupName : null)}
      getOptionLabel={option => option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox size={size} variant="outlined" type="checkbox" checked={selected} color="primary" />
          {option.name}
        </React.Fragment>
      )}
      value={defaultValues}
      renderInput={params => <TextField {...params} size={size} variant="outlined" fullWidth label={label} />}
      onChange={(_, selectedValues) => {
        const selectedValuesIds = selectedValues.map(v => v.id);
        const optionsIds = options.map(v => v.id);
        const updatedOptions = optionsIds.filter(v => selectedValuesIds.includes(v));
        setFieldValue(name, updatedOptions);
      }}
    />
  );
};

export default MultiselectDropdown;
